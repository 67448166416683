import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight, faArrowRight, faPhone } from "@fortawesome/free-solid-svg-icons"
import "../scss/modules/rdv-btn-component.scss"
import ReactModal from 'react-modal'

class resultsBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }

  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <>
        <button className={"item-main blue col-md-6"} onClick={this.handleModalOpen}>{this.props.title}</button>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Modal rdv"
          className={"modal-rdv"}
          overlayClassName={"modal-rdv-overlay"}
        >
          <h2>Résultats examens</h2>
          <p>Service <b>Radiologie-échographie-mammographie et ostéodensitométrie</b></p>
          <i>62-64 Avenue du Maréchal Delattre de Tassigny, 94000 Créteil</i>
          <a
            href={this.props.data.allStrapiUrl.nodes[0].ResultatExamenSite1}
            target="_blank"
            rel="noreferrer"
          >
            <button className={"link"}> Récuperez vos résultats <FontAwesomeIcon icon={faArrowAltCircleRight}/></button>
          </a>

          <hr/>

          <p>Service <b>Scanner-IRM</b></p>
          <i>23 Rue du Moulin (arrière de l’hôpital Intercommunal de Créteil), 94000 Créteil</i>
          <a
            href={this.props.data.allStrapiUrl.nodes[0].ResultatExamenSite2}
            target="_blank"
            rel="noreferrer"
          >
            <button className={"link"}> Récuperez vos résultats <FontAwesomeIcon icon={faArrowAltCircleRight}/></button>
          </a>
          <button className={"close-modal-btn"} onClick={this.handleModalClose}>Fermer</button>
        </ReactModal>
      </>
    )
  }
}

export default resultsBtn
