import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import "../scss/modules/floating-btn-component.scss"

class floatingBtn extends React.Component {
  render() {
    return (
      <div className={"floating-btn-container"}>
        <a href="tel:0142079433">
          <button className={"floating-btn"}><FontAwesomeIcon icon={faPhone}/></button>
        </a>
      </div>
    )
  }
}

export default floatingBtn
